import { Box } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useConsentData } from "../../00-globals/TruendoCookieConsent/TruendoConsentProvider";
import { BlockedComponent } from "../../02-molecules/BlockedComponent/BlockedComponent";
import { EmbedInstagramProps } from "./EmbedInstagramInterfaces";
import { useEmbedInstagramStyles } from "./EmbedInstagramStyles";

const EmbedInstagram = (props: EmbedInstagramProps): JSX.Element => {
  const { classes } = useEmbedInstagramStyles();
  const { services } = useConsentData();
  const isAllowed = !!services.facebook_instagram;

  useEffect(() => {
    if (isAllowed) {
      setTimeout(() => window.instgrm?.Embeds?.process?.(), 0);
    }
  }, [isAllowed]);

  return isAllowed ? (
    <Box className={classes.instagramStyles}>
      {props.blockquoteElement}

      <Helmet>
        <script
          async={true}
          src="https://www.instagram.com/embed.js"
          charSet="utf-8"
        />
      </Helmet>
    </Box>
  ) : (
    <BlockedComponent
      label={"Um den Instagram-Beitrag anzuzeigen, fehlen 🍪"}
      buttonAction={() => window.Truendo?.toggleContent()}
      buttonText={"Cookies akzeptieren"}
    />
  );
};

export default EmbedInstagram;
