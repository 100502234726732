import { Box, Typography } from "@mui/material";
import { HorizontalSliderProps } from "./HorizontalSliderInterfaces";
import { useHorizontalSliderStyles } from "./HorizontalSliderStyles";

const HorizontalSlider = ({
  children,
  title,
}: HorizontalSliderProps): JSX.Element => {
  const { classes } = useHorizontalSliderStyles();

  return (
    <Box className={classes.wrapper}>
      {title && (
        <Typography variant="h5" color="secondary.dark">
          {title}
        </Typography>
      )}

      <Box component="section" className={classes.innerWrapper}>
        <Box className={classes.inner}>
          {children.map((c, index) => (
            <Box key={index} className={classes.singleElement}>
              {c}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default HorizontalSlider;
