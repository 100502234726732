import { Box, Typography } from "@mui/material";
import { useConsentData } from "../../00-globals/TruendoCookieConsent/TruendoConsentProvider";
import { BlockedComponent } from "../../02-molecules/BlockedComponent/BlockedComponent";
import { EmbedSpotifyProps } from "./EmbedSpotifyInterfaces";
import { useEmbedSpotifyStyles } from "./EmbedSpotifyStyles";

const EmbedSpotify = (props: EmbedSpotifyProps): JSX.Element => {
  const { classes } = useEmbedSpotifyStyles();
  const { services } = useConsentData();
  const isAllowed = !!services.spotify;

  return isAllowed ? (
    <Box className={classes.wrapper}>
      {props.caption && (
        <Typography className={classes.title} variant="h3">
          {props.caption}
        </Typography>
      )}

      <Box className={classes.embedStyles}>{props.iframeElement}</Box>
    </Box>
  ) : (
    <BlockedComponent
      label={"Um das Spotify Widget anzuzeigen, fehlen 🍪"}
      buttonAction={() => window.Truendo?.toggleContent()}
      buttonText={"Cookies akzeptieren"}
    />
  );
};

export default EmbedSpotify;
