import { assertTruthy } from "@nvon/baseline";
import { domToReact } from "html-react-parser";
import EmbedSpotify from "../../../../components/01-atoms/EmbedSpotify/EmbedSpotify";
import { assertTag } from "../assertTag";
import { ClassNameToComponentMap } from "../htmlParsingInterfaces";
import { nodeIsTagWithName } from "../nodeIsTagWithName";
import { textFromElement } from "../textFromNode";

export const spotifyEmbed: ClassNameToComponentMap = {
  "wp-block-embed-spotify": ({ replacedElement }) => {
    assertTag(replacedElement);

    const captionElement = replacedElement.children.find(
      nodeIsTagWithName("figcaption")
    );

    const caption = captionElement
      ? textFromElement(captionElement)
      : undefined;

    const iframeWrapper = replacedElement.children[0];
    assertTag(iframeWrapper);

    const iframeElement = iframeWrapper.children.find(
      nodeIsTagWithName("iframe")
    );
    assertTruthy(iframeElement, "must have an iframe");

    return (
      <EmbedSpotify
        caption={caption}
        iframeElement={<>{domToReact([iframeElement])}</>}
      />
    );
  },
};
