import HorizontalSlider from "../../../../components/02-molecules/HorizontalSlider/HorizontalSlider";
import { ClassNameToComponentMap } from "../htmlParsingInterfaces";
import { nodeIsTagWithName } from "../nodeIsTagWithName";
import { textFromElement } from "../textFromNode";

export const wpImageGallery: ClassNameToComponentMap = {
  "wp-block-gallery": ({ children, replacedElement }) => {
    const relevantChildren = children.filter((c) => typeof c !== "string");

    const captionElement = replacedElement.children.find(
      nodeIsTagWithName("figcaption")
    );

    const caption = captionElement
      ? textFromElement(captionElement)
      : undefined;

    if (caption) {
      console.warn(
        `gallery has caption, but it's not used. Caption: ${caption}`
      );
    }

    return <HorizontalSlider>{relevantChildren}</HorizontalSlider>;
  },
};
