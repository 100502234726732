import { Element as HtmlElement } from "html-react-parser";
import { nodeIsText } from "./nodeIsText";

/**
 * Gets the first text node content, or an empty string if none is found.
 */
export const textFromElement = (input: HtmlElement): string => {
  const firstChild = input.children.find(nodeIsText);

  return firstChild?.data || "";
};
