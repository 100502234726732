import { domToReact } from "html-react-parser";
import EmbedInstagram from "../../../../components/01-atoms/EmbedInstagram/EmbedInstagram";
import { ClassNameToComponentMap } from "../htmlParsingInterfaces";
import { nodeIsTag } from "../nodeIsTag";

export const instagramEmbed: ClassNameToComponentMap = {
  "instagram-media": ({ replacedElement }) => {
    if (!nodeIsTag(replacedElement)) {
      throw Error(`Expected to have a tag here.`);
    }

    const blockquoteElement = <>{domToReact([replacedElement])}</>;

    return <EmbedInstagram blockquoteElement={blockquoteElement} />;
  },
};
