import { Button, Paper, Typography } from "@mui/material";
import { BlockedComponentProps } from "./BlockedComponentInterfaces";
import { useBlockedComponentStyles } from "./BlockedComponentStyles";

/**
 * Placeholder for a component that's not showing because of missing consent.
 */
export const BlockedComponent = (props: BlockedComponentProps): JSX.Element => {
  const { classes, cx } = useBlockedComponentStyles();

  return (
    <Paper className={cx(classes.wrapper, props.className)}>
      <Typography className={classes.blockedMessage} variant="h3">
        {props.label}
      </Typography>

      <Button
        variant={"contained"}
        onClick={props.buttonAction}
        className={classes.acceptButton}
      >
        <Typography variant="button"> {props.buttonText}</Typography>
      </Button>
    </Paper>
  );
};
