import { makeStyles } from "tss-react/mui";

export const useBlockedComponentStyles = makeStyles({
  name: "BlockedComponent",
})((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    margin: theme.spacing(2, 0),
    "& h3": {
      marginTop: "0px",
      marginBlock: theme.spacing(0.5),
      textAlign: "center",
    },
  },
  blockedMessage: {
    magrinTop: "0px",
  },
  acceptButton: {
    marginTop: theme.spacing(1),

    marginBlock: "0px !important",
    "& h6": {
      fontSize: "14px",
    },
  },
}));
