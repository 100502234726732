import { domToReact } from "html-react-parser";
import WpGutenbergImage from "../../../../components/01-atoms/WpGutenbergImage/WpGutenbergImage";
import { ClassNameToComponentMap } from "../htmlParsingInterfaces";
import { nodeIsTagWithName } from "../nodeIsTagWithName";
import { textFromElement } from "../textFromNode";

export const wpImageBlock: ClassNameToComponentMap = {
  "wp-block-image": ({ replacedElement }) => {
    const wrapperElement = replacedElement.children.find(
      nodeIsTagWithName("div")
    );

    const captionElement = replacedElement.children.find(
      nodeIsTagWithName("figcaption")
    );

    const caption = captionElement
      ? textFromElement(captionElement)
      : undefined;

    // Some images are not properly wrapped as we expect.
    // We currently suppose that this is caused by the way images are inserted in WP.
    // Images we insert "manually" seems to appear properly wrapped consistently.
    const isProperImage = !!wrapperElement;

    const innerElement = isProperImage ? wrapperElement : replacedElement;
    const innerReact = <>{domToReact([innerElement])}</>;

    return isProperImage ? (
      <WpGutenbergImage caption={caption} wrapperElement={innerReact} />
    ) : (
      innerReact
    );
  },
};
