import { makeStyles } from "tss-react/mui";

const sizeTheImages = (size: number) => ({
  "& .gatsby-image-wrapper": {
    minWidth: `${size}px`,
    maxWidth: `${size}px`,
    minHeight: `${size}px`,
    maxHeight: `${size}px`,
    "& .gatsby-image-wrapper": {
      minWidth: `${size}px`,
      maxWidth: `${size}px`,
      minHeight: `${size}px`,
      maxHeight: `${size}px`,
    },
  },
});

export const useHorizontalSliderStyles = makeStyles()((theme) => ({
  wrapper: {
    width: "100%",
  },
  innerWrapper: {
    maxWidth: "100%",
    overflowX: "scroll",
    width: "100%",
  },
  inner: {
    margin: theme.spacing(2, 0),
    width: "100%",
    display: "flex",
  },
  singleElement: {
    position: "relative",
    padding: "0 !important",
    marginRight: theme.spacing(2),
    ...sizeTheImages(500),
    [theme.breakpoints.down("sm")]: {
      ...sizeTheImages(200),
    },
  },
}));
