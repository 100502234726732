import { important } from "@nvon/react-toolbox";
import { makeStyles } from "tss-react/mui";

export const useWpGutenbergImageStyles = makeStyles()(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      left: important("50%"),
      transform: important("translateX(-50%)"),
      minWidth: "100%",
    },
  },
}));
