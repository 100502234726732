import { makeStyles } from "tss-react/mui";

export const useEmbedYoutubeStyles = makeStyles()((theme) => ({
  wrapper: {
    "& iframe": {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
    },
    "& h3": {
      textAlign: "center",
    },
  },
  youtubeStlyes: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    flexDirection: "column",
    position: "relative",
    width: "100%",
    height: "0",
    paddingBottom: "56.25%",
  },
}));
