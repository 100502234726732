import { Box, Typography } from "@mui/material";
import { useConsentData } from "../../00-globals/TruendoCookieConsent/TruendoConsentProvider";
import { BlockedComponent } from "../../02-molecules/BlockedComponent/BlockedComponent";
import { EmbedYoutubeProps } from "./EmbedYoutubeInterfaces";
import { useEmbedYoutubeStyles } from "./EmbedYoutubeStyles";

const EmbedYoutube = (props: EmbedYoutubeProps): JSX.Element => {
  const { classes } = useEmbedYoutubeStyles();
  const { services } = useConsentData();
  const isAllowed = !!services.google_youtube;

  return isAllowed ? (
    <Box className={classes.wrapper}>
      {props.caption && <Typography variant="h3">{props.caption}</Typography>}

      <Box className={classes.youtubeStlyes}>{props.iframeElement}</Box>
    </Box>
  ) : (
    <BlockedComponent
      label={"Um den Youtube-Beitrag anzuzeigen, fehlen 🍪"}
      buttonAction={() => window.Truendo?.toggleContent()}
      buttonText={"Cookies akzeptieren"}
    />
  );
};

export default EmbedYoutube;
