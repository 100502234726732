import { assertTruthy } from "@nvon/baseline";
import { Element as HtmlElement, Node } from "html-react-parser";
import { nodeIsTag } from "./nodeIsTag";

export const assertTag: (input: Node) => asserts input is HtmlElement = (
  input: Node
): asserts input is HtmlElement => {
  const isTag = nodeIsTag(input);
  assertTruthy(isTag, "Node was asserted to be an Element, but isn't.");
};
