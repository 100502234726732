import { Element as HtmlElement, Node } from "html-react-parser";
import { HtmlTag } from "../../../interfaces/variousInterfaces";

/**
 * A higher-order function, primarily to be used in .find/.filter expressions:
 *
 * @example
 * ```
 * const captionElement = replacedElement.children.find(
 *    nodeIsTagWithName("figcaption")
 * );
 * ```
 */
export const nodeIsTagWithName =
  (tagName: HtmlTag): ((input: Node) => input is HtmlElement) =>
  (input): input is HtmlElement =>
    input.type === "tag" && (input as HtmlElement).name === tagName;
