import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useConsentData } from "../../00-globals/TruendoCookieConsent/TruendoConsentProvider";
import { BlockedComponent } from "../../02-molecules/BlockedComponent/BlockedComponent";
import { EmbedTwitterProps } from "./EmbedTwitterInterfaces";
import { useEmbedTwitterStyles } from "./EmbedTwitterStyles";

const EmbedTwitter = (props: EmbedTwitterProps): JSX.Element => {
  const { classes } = useEmbedTwitterStyles();
  const { services } = useConsentData();
  const isAllowed = !!services.twitter_sharing;

  return isAllowed ? (
    <Box className={classes.twitterStyles}>
      <Helmet>
        <script
          async={true}
          src="https://platform.twitter.com/widgets.js"
          charSet="utf-8"
        />
      </Helmet>

      {props.caption && <Typography variant="h3">{props.caption}</Typography>}

      {props.twitterBlockquoteElement}
    </Box>
  ) : (
    <BlockedComponent
      label={"Um den Twitter-Beitrag anzuzeigen, fehlen 🍪"}
      buttonAction={() => window.Truendo?.toggleSharing()}
      buttonText={"Cookies akzeptieren"}
    />
  );
};

export default EmbedTwitter;
