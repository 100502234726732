import { makeStyles } from "tss-react/mui";

export const useEmbedSpotifyStyles = makeStyles()((theme) => ({
  wrapper: {
    "& iframe": {},
  },
  title: {
    textAlign: "center",
    display: "none",
  },
  embedStyles: {
    marginTop: theme.spacing(2),
  },
}));
