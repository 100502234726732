import { Link as MuiLink } from "@mui/material";
import { Link as GatsbyLink } from "gatsby";
import { TagToComponentMap } from "./htmlParsingInterfaces";

/**
 * Links coming from wordpress, mapped to:
 *  - a span (if no href is given)
 *  - a Mui Link using a gatsby Link for internal links
 *  - a plain Mui link for external links
 */
export const mappedLinks: TagToComponentMap = {
  a: ({ children, replacedElement }) => {
    const href = replacedElement.attribs.href;

    if (!href) {
      return <span>{children}</span>;
    }

    // Regex: starts with a slash, followed by a non-slash character
    const isInternalLink = href.match(/^\/[^/]/);

    if (isInternalLink) {
      return (
        <MuiLink underline="always" component={GatsbyLink} to={href}>
          {children}
        </MuiLink>
      );
    }

    return (
      <MuiLink target="_blank" underline="always" href={href}>
        {children}
      </MuiLink>
    );
  },
};
