import { Box, Typography } from "@mui/material";
import { useInterval } from "react-use";
import { performGatsbyImageMagic } from "./performGatsbyImageMagic";
import { WpGutenbergImageProps } from "./WpGutenbergImageInterfaces";
import { useWpGutenbergImageStyles } from "./WpGutenbergImageStyles";

const WpGutenbergImage = (props: WpGutenbergImageProps): JSX.Element => {
  const { classes } = useWpGutenbergImageStyles();

  useInterval(performGatsbyImageMagic, 500);

  return (
    <Box className={classes.wrapper}>
      {props.wrapperElement}

      {props.caption && (
        <Typography variant="subtitle1">{props.caption}</Typography>
      )}
    </Box>
  );
};

export default WpGutenbergImage;
