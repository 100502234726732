import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import HyphenatedTypography from "../../../components/01-atoms/HyphenatedTypography/HyphenatedTypography";
import { allHeadlineTags } from "../../styling/allHeadlines";
import { TagToComponentMap } from "./htmlParsingInterfaces";

/**
 * All headlines mapped to their Typography counterpart, hyphenated or not.
 */
export const mapAllHeadlines = (hyphenate: boolean): TagToComponentMap => {
  const Component = hyphenate ? HyphenatedTypography : Typography;

  return Object.fromEntries(
    allHeadlineTags.map((tag) => [
      tag,
      (props) => (
        <Component
          variant={tag as Variant}
          className="marginTop"
          {...props.replacedElement.attribs}
        >
          {props.children}
        </Component>
      ),
    ])
  );
};
