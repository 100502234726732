/* components in here are very small */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { Typography } from "@mui/material";
import { instagramEmbed } from "./gutenbergBlocks/instagramEmbed";
import { spotifyEmbed } from "./gutenbergBlocks/spotifyEmbed";
import { twitterEmbed } from "./gutenbergBlocks/twitterEmbed";
import { wpImageBlock } from "./gutenbergBlocks/wpImageBlock";
import { wpImageGallery } from "./gutenbergBlocks/wpImageGallery";
import { youtubeEmbed } from "./gutenbergBlocks/youtubeEmbed";
import {
  ClassNameToComponentMap,
  HtmlToComponentMapping,
  TagToComponentMap,
} from "./htmlParsingInterfaces";
import { mapAllHeadlines } from "./mapAllHeadlines";
import { mappedLinks } from "./mapAllLinks";

const commonElements: TagToComponentMap = {
  p: (props) => <Typography variant="body1">{props.children}</Typography>,
  ...mappedLinks,
};

const gutenbergElements: ClassNameToComponentMap = {
  ...twitterEmbed,
  ...instagramEmbed,
  ...youtubeEmbed,
  ...spotifyEmbed,
  ...wpImageGallery,
  ...wpImageBlock,
};

export const cleanupWpHeadlines: TagToComponentMap = {
  // we need the fragment to return a JSX.Element
  // eslint-disable-next-line react/jsx-no-useless-fragment
  p: (props) => <>{props.children}</>,
};

export const mapWpContent: HtmlToComponentMapping = {
  tagMap: {
    ...commonElements,
    ...mapAllHeadlines(true),
  },
  classNameMap: {
    ...gutenbergElements,
  },
};
