import { makeStyles } from "tss-react/mui";

export const useEmbedTwitterStyles = makeStyles()((theme) => ({
  twitterStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(),
    flexDirection: "column",
    "& h3": {
      textAlign: "center",
      marginBottom: theme.spacing(0),
    },
  },
}));
