import { assertTruthy } from "@nvon/baseline";
import { domToReact } from "html-react-parser";
import EmbedTwitter from "../../../../components/01-atoms/EmbedTwitter/EmbedTwitter";
import { assertTag } from "../assertTag";
import { ClassNameToComponentMap } from "../htmlParsingInterfaces";
import { nodeIsTagWithName } from "../nodeIsTagWithName";
import { textFromElement } from "../textFromNode";

export const twitterEmbed: ClassNameToComponentMap = {
  "wp-block-embed-twitter": ({ replacedElement }) => {
    assertTag(replacedElement);

    const captionElement = replacedElement.children.find(
      nodeIsTagWithName("figcaption")
    );
    const caption = captionElement
      ? textFromElement(captionElement)
      : undefined;

    const blockquoteWrapper = replacedElement.children.find(
      nodeIsTagWithName("div")
    );
    assertTruthy(blockquoteWrapper, "must have a blockquote wrapper");

    return (
      <EmbedTwitter
        caption={caption}
        twitterBlockquoteElement={<>{domToReact(blockquoteWrapper.children)}</>}
      />
    );
  },
};
